import { useState } from "react";

function TermSelect(props) {

  const [defaultValue, setDefaultValue] = useState(props.coursesObj.SISTermID);

  function handleStateChange (e) {
    let coursesObj = props.coursesObj;
    setDefaultValue(e.target.value);
      props.setCoursesObj({
        ...coursesObj,
        SISTermID: e.target.value,
        default: false,
      });
  }

  return (
      <>
        {defaultValue === '202540'
            ?
            <>
              <label htmlFor="Term Selection" className="majorLabel">Term:</label>
              <input type="radio" id="Spring Semester 2025" name="SISTermID" value="202540" className="radio" checked
                     onChange={handleStateChange}/>
              <label htmlFor="Spring Semester 2025">Spring Semester 2025</label>
              <br></br>
              <input type="radio" id="Summer Semester 2025" name="SISTermID" value="202610" className="radio"
                     onChange={handleStateChange}/>
              <label htmlFor="Summer Semester 2025">Summer Semester 2025</label>
              <br></br>
              <input type="radio" id="Fall Semester 2025" name="SISTermID" value="202620" className="radio"
                     onChange={handleStateChange}/>
              <label htmlFor="Summer Semester 2025">Fall Semester 2025</label>
            </>
            :
            null
        }
        {defaultValue === '202610'
            ?
            <>
              <label htmlFor="Term Selection" className="majorLabel">Term:</label>
              <input type="radio" id="Spring Semester 2025" name="SISTermID" value="202540" className="radio"
                     onChange={handleStateChange}/>
              <label htmlFor="Spring Semester 2025">Spring Semester 2025</label>
              <br></br>
              <input type="radio" id="Summer Semester 2025" name="SISTermID" value="202610" className="radio" checked
                     onChange={handleStateChange}/>
              <label htmlFor="Summer Semester 2025">Summer Semester 2025</label>
              <br></br>
              <input type="radio" id="Fall Semester 2025" name="SISTermID" value="202620" className="radio"
                     onChange={handleStateChange}/>
              <label htmlFor="Summer Semester 2025">Fall Semester 2025</label>
            </>
            :
            null
        }
        {defaultValue === '202620'
            ?
            <>
              <label htmlFor="Term Selection" className="majorLabel">Term:</label>
              <input type="radio" id="Spring Semester 2025" name="SISTermID" value="202540" className="radio"
                     onChange={handleStateChange}/>
              <label htmlFor="Spring Semester 2025">Spring Semester 2025</label>
              <br></br>
              <input type="radio" id="Summer Semester 2025" name="SISTermID" value="202610" className="radio"
                     onChange={handleStateChange}/>
              <label htmlFor="Summer Semester 2025">Summer Semester 2025</label>
              <br></br>
              <input type="radio" id="Fall Semester 2025" name="SISTermID" value="202620" className="radio" checked
                     onChange={handleStateChange}/>
              <label htmlFor="Summer Semester 2025">Fall Semester 2025</label>
            </>
            :
            null
        }
      </>
  );
}

export default TermSelect;
