import { Route, Routes, useSearchParams } from "react-router-dom";
import Courses from "./components/courses/Courses";
import Filters from "./components/filters/Filters";
import Header from "./components/site_wrapper/Header";
import Head from "./components/site_wrapper/Head";
import './App.css';
import {useEffect, useState} from 'react';
import {domain} from "./constants/Global";
import Footer from "./components/site_wrapper/Footer";

function App() {

  const [instructors, setInstructors] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [instLoading, setInstLoading] = useState(false);
  const [subLoading, setSubLoading] = useState(false);
  const [count, setCount] = useState(0);

  function loadInstructors() {
    setInstLoading(true);
    fetch(
        domain + "/api-proxy/workday?_api_proxy_uri=INT172_Aims_Public_Schedule_Instructors%3Fformat%3Djson",
        {
          method: "GET",
        }
    )
        .then((response) => response.json())
        .then((data) => {
          setInstructors(data.Report_Entry);
          setInstLoading(false);
        })
        .catch((error) => console.log(error));
  }

  function loadSubjects() {
    setSubLoading(true);
    fetch(
        domain + "/api-proxy/workday?_api_proxy_uri=INT172_Aims_Public_Schedule_Subjects%3Fformat%3Djson",
        {
          method: "GET",
        }
    )
        .then((response) => response.json())
        .then((data) => {
          setSubjects(data.Report_Entry);
          setSubLoading(false);
        })
        .catch((error) => console.log(error));
  }

  const [searchParams] = useSearchParams();

  const [coursesObj, setCoursesObj] = useState({
    // default: true,
    SISTermID: searchParams.get('SISTermID') ? searchParams.get('SISTermID') : '202540',
    Online_Only: searchParams.get('Online_Only') ? searchParams.get('Online_Only') : '',
    Subject: searchParams.get('Subject') ? searchParams.get('Subject') : '',
    Allowed_Locations: searchParams.get('Allowed_Locations') ? searchParams.get('Allowed_Locations') : '',
    CourseNumber: searchParams.get('CourseNumber') ? searchParams.get('CourseNumber') : '',
    SectionStatus: searchParams.get('SectionStatus') ? searchParams.get('SectionStatus') : '',
    StartAfter: searchParams.get('StartAfter') ? searchParams.get('StartAfter') : '',
    EndBy: searchParams.get('EndBy') ? searchParams.get('EndBy') : '',
    IsMonday: searchParams.get('IsMonday') ? searchParams.get('IsMonday') : '',
    IsTuesday: searchParams.get('IsTuesday') ? searchParams.get('IsTuesday') : '',
    IsWednesday: searchParams.get('IsWednesday') ? searchParams.get('IsWednesday') : '',
    IsThursday: searchParams.get('IsThursday') ? searchParams.get('IsThursday') : '',
    IsFriday: searchParams.get('IsFriday') ? searchParams.get('IsFriday') : '',
    IsSaturday: searchParams.get('IsSaturday') ? searchParams.get('IsSaturday') : '',
    IsSunday: searchParams.get('IsSunday') ? searchParams.get('IsSunday') : '',
    StartDate: searchParams.get('StartDate') ? searchParams.get('StartDate') : '',
    EndDate: searchParams.get('EndDate') ? searchParams.get('EndDate') : '',
    Title: searchParams.get('Title') ? searchParams.get('Title') : '',
    Instructor: searchParams.get('Instructor') ? searchParams.get('Instructor') : '',
  });

  function filterCheck (obj) {
    Object.values(obj).forEach(value => {
      if (value) {
        setCount(prevCount => prevCount + 1)
      }
    });
  }

  useEffect(() => {
    loadInstructors();
    loadSubjects();
  }, []);

  // eslint-disable-next-line
  useEffect(() => {
    filterCheck(coursesObj);
  }, [coursesObj]);

  return (
    <div>
      <Head />
        <div class="l-layout-container">
          <Header />
            <div class="paragraph paragraph--type--layout paragraph--view-mode--default c-layout">
                <div class="l-section--normal t-normal t-gutter-normal t- l-section">
                    <div class="l-section__inner">
                        <div class="l-section__col l-section__col--">
                          <h2>Public Class Schedule</h2>
                          <p><b>To use this tool, you need to select at least 2 filters. Also, the tool will start searching when a filter is selected. The “View Results” button will take you to the results section of the page.</b></p>
                          <p>A list of known issues and enhancements in development can be <a href="https://www.aims.edu/class-schedule/known-issues">viewed here.</a></p>
                              <div id="searchResults"></div>
                              <Filters coursesObj={coursesObj} setCoursesObj={setCoursesObj} instructors={instructors} instLoading={instLoading} subjects={subjects} subLoading={subLoading}/>
                              <Routes>
                                <Route path="/" element={ count > 2 ? <Courses coursesData={coursesObj} instructors={instructors} instLoading={instLoading} subjects={subjects} subLoading={subLoading}/> : null} >
                                  <Route path="search" element={<Courses coursesData={coursesObj} instructors={instructors} instLoading={instLoading} subjects={subjects} subLoading={subLoading}/>} />
                                </Route>
                              </Routes>
                        </div>
                    </div>
                </div>
            </div>
          <Footer />
        </div>
    </div>
  );
}

export default App;
