import "./NoResults.css";
import spaceman from '../../../images/class_schedule_drawing.png';

function NoResults() {


  return(
    <div id="noResults">
      <img id="spacemanIMG" alt='No results infographic. Astronaut floating in space' src={spaceman}/>
      <h2>No Results Found</h2>
      <h3>We couldn't find what you searched for.<br></br>Try searching again.</h3>
    </div>
  )

}

export default NoResults;
